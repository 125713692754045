import React, { useState, useEffect } from 'react';
import Layout from '../components/common/layout/layout';
import SEO from '../components/common/layout/seo';
import Navigation from '../components/common/navigation/navigation';
import { Section } from '../components/global';
import Footer from '../components/sections/footer';
import styled from 'styled-components';
import { Link } from 'gatsby';

const PricingPage = () => {
  const [hostRoute, setHostRoute] = useState('');

  useEffect(() => {
    if (typeof window !== `undefined`) setHostRoute(window.location.host);
  }, []);

  const loginRoute = (host) => {
    if (host.includes('dealmap')) {
      return 'https://app.dealmap.com';
    } else if (host.includes('dinghydeal')) {
      return 'https://app.dinghydeal.com';
    } else if (host.includes('thelonehipster')) {
      return 'https://app.thelonehipster.com';
    } else if (host.includes('splatmapper')) {
      return 'https://app.splatmapper.com';
    } else if (host.includes('localhost')) {
      return 'localhost:4000';
    } else {
      return '/';
    }
  };
  return (
    <Layout>
      <SEO title="Pricing" />
      <Navigation style={{ background: 'white' }} />

      <MainSection>
        <Card>
          <div>
            <h4 style={{ marginBottom: '10px' }}>Pricing</h4>
            <h5 style={{ marginTop: '0px' }}>Join the beta for:</h5>
            <h1 className="betaPricing">$0</h1>
          </div>
          <div>
            <h4 className="pricingH4">Post Launch Pricing:</h4>
            <h5 className="pricingLine">$30/mo for first 3 users</h5>{' '}
            <h5 className="pricingLine">$5 per additional user</h5>
          </div>
          <ButtonContainer>
            <TryItButton href={`${loginRoute(hostRoute)}/initial-signup`}>Sign up now</TryItButton>
          </ButtonContainer>
        </Card>
      </MainSection>
      <Footer />
    </Layout>
  );
};

export default PricingPage;

const MainSection = styled(Section)`
  background: rgb(17, 75, 95);
`;

const Card = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  height: 460px;
  background: white;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  margin-top: 80px;
  border-radius: 4px;
  .pricingH4 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .betaPricing {
    font-size: 68px;
    margin-bottom: 30px;
  }
  .pricingLine {
    margin: 7px 0px;
  }
`;

const TryItButton = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 80px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
